<template>
  <div class="article-list">
    <l-title-card name="文章管理">
      <el-button
          type="primary"
          icon="el-icon-plus"
          @click="addArticle"
      >
        添加
      </el-button>
    </l-title-card>
    <div class="main-content-container">
      <el-table
          :data="list"
          border
          stripe
      >
        <el-table-column
            label="序号"
            type="index"
            width="50px"
        >
        </el-table-column>
        <el-table-column
            label="标题"
            prop="title"
        >
        </el-table-column>
        <el-table-column
            label="分类"
            prop="typeName"
        >
          <template slot-scope="scope">
            <span class="link">
              {{ formatTypeName(scope.row) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
            label="标签"
        >
          <template slot-scope="scope">
            {{ formatTagName(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column
            label="创建时间"
            prop="createTime"
        >
          <template slot-scope="scope">
            {{ $dayJS(scope.row.createTime).format("YYYY/MM/DD HH:mm:ss") }}
          </template>
        </el-table-column>
        <el-table-column
            label="更新时间"
            prop="updateTime"
        >
          <template slot-scope="scope">
            {{ $dayJS(scope.row.updateTime).format("YYYY/MM/DD HH:mm:ss") }}
          </template>
        </el-table-column>
        <el-table-column
            label="操作"
            width="120px"
        >
          <template slot-scope="scope">
            <l-icon
                name="icon-sousuo"
                class="detail"
                @click="showDetails(scope.row)"
            >
            </l-icon>
            <l-icon
                name="icon-bianji"
                class="edit"
                @click="editArticle(scope.row)"
            >
            </l-icon>
            <l-icon
                name="icon-shanchukai"
                class="delete"
                @click="deleteArticle(scope.row)"
            >
            </l-icon>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
        :title="articleInfo.title"
        :visible.sync="showDialog"
        width="1000px"
        class="article-detail"
    >
      <div class="article-basic">
        <div class="article-basic-item">
          <l-icon name="icon-calendar"></l-icon>
          {{ `发布时间:${$dayJS(articleInfo.createTime).format('YYYY/MM/DD HH:mm:ss')}` }}
        </div>
        <div class="article-basic-item">
          <l-icon name="icon-banbengengxinjilu"></l-icon>
          {{ `更新时间:${$dayJS(articleInfo.updateTime).format('YYYY/MM/DD HH:mm:ss')}` }}
        </div>
        <div class="article-basic-item">
          <l-icon name="icon-yuedujilu"></l-icon>
          {{ `字数统计:${formatArticleLength(articleInfo)}` }}
        </div>
        <div class="article-basic-item">
          <l-icon name="icon-tongjibaobiao"></l-icon>
          {{ `阅读量:${articleInfo.readNum}` }}
        </div>
      </div>
      <div class="article-description">
        {{ articleInfo.description }}
      </div>
      <div class="article-content">
        <mavon-editor
            v-model="articleInfo.content"
            codeStyle="atelier-forest-dark"
            defaultOpen="preview"
            :subfield="false"
            :toolbarsFlag="false"
        >
        </mavon-editor>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {formatTypeName, formatTagName, formatArticleLength} from "@/tools";

export default {
  name: "index",
  data() {
    return {
      list: [],
      articleInfo: {},
      showDialog: false
    };
  },
  methods: {
    formatTypeName,
    formatTagName,
    formatArticleLength,

    /**
     * @description 跳转至添加新文章
     */
    addArticle() {
      this.$router.push({
        name: "addArticle"
      });
    },
    /**
     * @description 获取文章清单
     */
    async getArticleList() {
      this.list = await this.$api.article.getArticleList({
        pageSize: 10,
        pageNo: 1
      });
    },
    /**
     * @description 删除指定文章
     * @param itemData
     */
    deleteArticle(itemData) {
      this.$confirm(`你确定要删除${itemData.title}?`, "温馨提示", {
        type: "warning"
      }).then(async () => {
        const result = await this.$api.article.deleteArticleInfo({
          id: itemData.id
        });
        if (result) {
          this.$notify({
            type: "success",
            title: "操作成功",
            message: `${itemData.title}已删除成功`
          })
          this.getArticleList()
        }
      }).catch(() => {
        console.warn("放弃执行了")
      })
    },
    /**
     * @description 编辑文章信息
     * @param itemData
     */
    editArticle(itemData) {
      this.$router.push({
        name: "editArticle",
        params: {
          id: itemData.id
        }
      });
    },
    /**
     * @description 查看文章详情
     * @param itemData
     */
    showDetails(itemData) {
      this.showDialog = true;
      this.articleInfo = itemData;
    }
  },
  created() {
    this.getArticleList();
  }
};

</script>
<style lang="scss">
</style>
